import React from "react";

import Layout from "components/Layout";
import InfoPage from "components/InfoPage";

import NotFoundIcon from "icons/not-found.svg";

const NotFound = () => {
  return (
    <Layout
      location="404"
      blue_header
      seo={{ title: "404 - Strona nie istnieje" }}
    >
      <InfoPage
        icon={<NotFoundIcon />}
        header="404"
        text="Przepraszamy, strony nie odnaleziono"
      />
    </Layout>
  );
};

export default NotFound;
