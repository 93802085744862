import "./styles.scss";
import React from "react";

import Button from "../Button";

const mainClass = "info-page";

const InfoPage = ({ icon, header, text }) => {
  return (
    <div className={mainClass}>
      <div className="container">
        {icon}
        <div className={`${mainClass}__text`}>
          <h1>{header}</h1>
          <p>{text}</p>
          <Button href="/">Strona główna</Button>
        </div>
      </div>
    </div>
  );
};

export default InfoPage;
